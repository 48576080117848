import {
  Box,
  Card,
  CardContent,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useGetWordsLearningStats } from "../api/generated/spanishLearningAPI";

export const LearningStatistics = () => {
  const { data: stats, isLoading } = useGetWordsLearningStats();

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" p={2}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
        Learning Progress
      </Typography>
      <Box display="flex" gap={2} justifyContent="center">
        <Card sx={{ minWidth: 200 }}>
          <CardContent>
            <Typography color="text.secondary" gutterBottom>
              Total Words
            </Typography>
            <Typography variant="h4">{stats?.totalWords || 0}</Typography>
          </CardContent>
        </Card>

        <Card sx={{ minWidth: 200 }}>
          <CardContent>
            <Typography color="text.secondary" gutterBottom>
              Learned Words
            </Typography>
            <Typography variant="h4" color="success.main">
              {stats?.learnedWords || 0}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {stats?.totalWords
                ? `${(
                    ((stats.learnedWords || 0) / stats.totalWords) *
                    100
                  ).toFixed(2)}%`
                : "0.00%"}
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ minWidth: 200 }}>
          <CardContent>
            <Typography color="text.secondary" gutterBottom>
              Skipped Words
            </Typography>
            <Typography variant="h4" color="warning.main">
              {stats?.skippedWords || 0}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {stats?.totalWords
                ? `${(
                    ((stats.skippedWords || 0) / stats.totalWords) *
                    100
                  ).toFixed(2)}%`
                : "0.00%"}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};
