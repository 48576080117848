/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Spanish Learning API
 * API for learning Spanish vocabulary
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  GetWordsLearningStats200,
  GetWordsNotLearnedParams,
  GetWordsNotLearnedWithExamples200Item,
  GetWordsNotLearnedWithExamplesParams,
  GetWordsRandomNotLearnedWithExamples200Item,
  GetWordsRandomNotLearnedWithExamplesParams,
  PostAiGenerateMemoryAssociationsBody,
  PostAiGenerateRelatedWordsBody,
  PostWordsAnswerBody,
  Word
} from './spanishLearningAPI.schemas'
import { customInstance } from '../mutator/custom-instance';



/**
 * @summary Generate related words for a given word
 */
export const postAiGenerateRelatedWords = (
    postAiGenerateRelatedWordsBody: PostAiGenerateRelatedWordsBody,
 signal?: AbortSignal
) => {
      
      
      return customInstance<void>(
      {url: `/ai/generate-related-words`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAiGenerateRelatedWordsBody, signal
    },
      );
    }
  


export const getPostAiGenerateRelatedWordsMutationOptions = <TError = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAiGenerateRelatedWords>>, TError,{data: PostAiGenerateRelatedWordsBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postAiGenerateRelatedWords>>, TError,{data: PostAiGenerateRelatedWordsBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAiGenerateRelatedWords>>, {data: PostAiGenerateRelatedWordsBody}> = (props) => {
          const {data} = props ?? {};

          return  postAiGenerateRelatedWords(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostAiGenerateRelatedWordsMutationResult = NonNullable<Awaited<ReturnType<typeof postAiGenerateRelatedWords>>>
    export type PostAiGenerateRelatedWordsMutationBody = PostAiGenerateRelatedWordsBody
    export type PostAiGenerateRelatedWordsMutationError = void

    /**
 * @summary Generate related words for a given word
 */
export const usePostAiGenerateRelatedWords = <TError = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAiGenerateRelatedWords>>, TError,{data: PostAiGenerateRelatedWordsBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postAiGenerateRelatedWords>>,
        TError,
        {data: PostAiGenerateRelatedWordsBody},
        TContext
      > => {

      const mutationOptions = getPostAiGenerateRelatedWordsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Generate memory association tips for a word
 */
export const postAiGenerateMemoryAssociations = (
    postAiGenerateMemoryAssociationsBody: PostAiGenerateMemoryAssociationsBody,
 signal?: AbortSignal
) => {
      
      
      return customInstance<void>(
      {url: `/ai/generate-memory-associations`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAiGenerateMemoryAssociationsBody, signal
    },
      );
    }
  


export const getPostAiGenerateMemoryAssociationsMutationOptions = <TError = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAiGenerateMemoryAssociations>>, TError,{data: PostAiGenerateMemoryAssociationsBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postAiGenerateMemoryAssociations>>, TError,{data: PostAiGenerateMemoryAssociationsBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAiGenerateMemoryAssociations>>, {data: PostAiGenerateMemoryAssociationsBody}> = (props) => {
          const {data} = props ?? {};

          return  postAiGenerateMemoryAssociations(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostAiGenerateMemoryAssociationsMutationResult = NonNullable<Awaited<ReturnType<typeof postAiGenerateMemoryAssociations>>>
    export type PostAiGenerateMemoryAssociationsMutationBody = PostAiGenerateMemoryAssociationsBody
    export type PostAiGenerateMemoryAssociationsMutationError = void

    /**
 * @summary Generate memory association tips for a word
 */
export const usePostAiGenerateMemoryAssociations = <TError = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAiGenerateMemoryAssociations>>, TError,{data: PostAiGenerateMemoryAssociationsBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postAiGenerateMemoryAssociations>>,
        TError,
        {data: PostAiGenerateMemoryAssociationsBody},
        TContext
      > => {

      const mutationOptions = getPostAiGenerateMemoryAssociationsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getWords = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<Word[]>(
      {url: `/words`, method: 'GET', signal
    },
      );
    }
  

export const getGetWordsQueryKey = () => {
    return [`/words`] as const;
    }

    
export const getGetWordsQueryOptions = <TData = Awaited<ReturnType<typeof getWords>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWords>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWordsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWords>>> = ({ signal }) => getWords(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWords>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetWordsQueryResult = NonNullable<Awaited<ReturnType<typeof getWords>>>
export type GetWordsQueryError = unknown


export function useGetWords<TData = Awaited<ReturnType<typeof getWords>>, TError = unknown>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWords>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWords>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetWords<TData = Awaited<ReturnType<typeof getWords>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWords>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWords>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetWords<TData = Awaited<ReturnType<typeof getWords>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWords>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useGetWords<TData = Awaited<ReturnType<typeof getWords>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWords>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetWordsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get not learned words
 */
export const getWordsNotLearned = (
    params?: GetWordsNotLearnedParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<void>(
      {url: `/words/not-learned`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetWordsNotLearnedQueryKey = (params?: GetWordsNotLearnedParams,) => {
    return [`/words/not-learned`, ...(params ? [params]: [])] as const;
    }

    
export const getGetWordsNotLearnedQueryOptions = <TData = Awaited<ReturnType<typeof getWordsNotLearned>>, TError = unknown>(params?: GetWordsNotLearnedParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsNotLearned>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWordsNotLearnedQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWordsNotLearned>>> = ({ signal }) => getWordsNotLearned(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWordsNotLearned>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetWordsNotLearnedQueryResult = NonNullable<Awaited<ReturnType<typeof getWordsNotLearned>>>
export type GetWordsNotLearnedQueryError = unknown


export function useGetWordsNotLearned<TData = Awaited<ReturnType<typeof getWordsNotLearned>>, TError = unknown>(
 params: undefined |  GetWordsNotLearnedParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsNotLearned>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWordsNotLearned>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetWordsNotLearned<TData = Awaited<ReturnType<typeof getWordsNotLearned>>, TError = unknown>(
 params?: GetWordsNotLearnedParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsNotLearned>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWordsNotLearned>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetWordsNotLearned<TData = Awaited<ReturnType<typeof getWordsNotLearned>>, TError = unknown>(
 params?: GetWordsNotLearnedParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsNotLearned>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Get not learned words
 */

export function useGetWordsNotLearned<TData = Awaited<ReturnType<typeof getWordsNotLearned>>, TError = unknown>(
 params?: GetWordsNotLearnedParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsNotLearned>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetWordsNotLearnedQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get not learned words with example sentences
 */
export const getWordsNotLearnedWithExamples = (
    params?: GetWordsNotLearnedWithExamplesParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<GetWordsNotLearnedWithExamples200Item[]>(
      {url: `/words/not-learned-with-examples`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetWordsNotLearnedWithExamplesQueryKey = (params?: GetWordsNotLearnedWithExamplesParams,) => {
    return [`/words/not-learned-with-examples`, ...(params ? [params]: [])] as const;
    }

    
export const getGetWordsNotLearnedWithExamplesQueryOptions = <TData = Awaited<ReturnType<typeof getWordsNotLearnedWithExamples>>, TError = unknown>(params?: GetWordsNotLearnedWithExamplesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsNotLearnedWithExamples>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWordsNotLearnedWithExamplesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWordsNotLearnedWithExamples>>> = ({ signal }) => getWordsNotLearnedWithExamples(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWordsNotLearnedWithExamples>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetWordsNotLearnedWithExamplesQueryResult = NonNullable<Awaited<ReturnType<typeof getWordsNotLearnedWithExamples>>>
export type GetWordsNotLearnedWithExamplesQueryError = unknown


export function useGetWordsNotLearnedWithExamples<TData = Awaited<ReturnType<typeof getWordsNotLearnedWithExamples>>, TError = unknown>(
 params: undefined |  GetWordsNotLearnedWithExamplesParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsNotLearnedWithExamples>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWordsNotLearnedWithExamples>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetWordsNotLearnedWithExamples<TData = Awaited<ReturnType<typeof getWordsNotLearnedWithExamples>>, TError = unknown>(
 params?: GetWordsNotLearnedWithExamplesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsNotLearnedWithExamples>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWordsNotLearnedWithExamples>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetWordsNotLearnedWithExamples<TData = Awaited<ReturnType<typeof getWordsNotLearnedWithExamples>>, TError = unknown>(
 params?: GetWordsNotLearnedWithExamplesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsNotLearnedWithExamples>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Get not learned words with example sentences
 */

export function useGetWordsNotLearnedWithExamples<TData = Awaited<ReturnType<typeof getWordsNotLearnedWithExamples>>, TError = unknown>(
 params?: GetWordsNotLearnedWithExamplesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsNotLearnedWithExamples>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetWordsNotLearnedWithExamplesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Save answer for a word
 */
export const postWordsAnswer = (
    postWordsAnswerBody: PostWordsAnswerBody,
 signal?: AbortSignal
) => {
      
      
      return customInstance<void>(
      {url: `/words/answer`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postWordsAnswerBody, signal
    },
      );
    }
  


export const getPostWordsAnswerMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWordsAnswer>>, TError,{data: PostWordsAnswerBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postWordsAnswer>>, TError,{data: PostWordsAnswerBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postWordsAnswer>>, {data: PostWordsAnswerBody}> = (props) => {
          const {data} = props ?? {};

          return  postWordsAnswer(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostWordsAnswerMutationResult = NonNullable<Awaited<ReturnType<typeof postWordsAnswer>>>
    export type PostWordsAnswerMutationBody = PostWordsAnswerBody
    export type PostWordsAnswerMutationError = unknown

    /**
 * @summary Save answer for a word
 */
export const usePostWordsAnswer = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWordsAnswer>>, TError,{data: PostWordsAnswerBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postWordsAnswer>>,
        TError,
        {data: PostWordsAnswerBody},
        TContext
      > => {

      const mutationOptions = getPostWordsAnswerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get statistics for a word
 */
export const getWordsWordIdStats = (
    wordId: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<void>(
      {url: `/words/${wordId}/stats`, method: 'GET', signal
    },
      );
    }
  

export const getGetWordsWordIdStatsQueryKey = (wordId: number,) => {
    return [`/words/${wordId}/stats`] as const;
    }

    
export const getGetWordsWordIdStatsQueryOptions = <TData = Awaited<ReturnType<typeof getWordsWordIdStats>>, TError = void>(wordId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsWordIdStats>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWordsWordIdStatsQueryKey(wordId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWordsWordIdStats>>> = ({ signal }) => getWordsWordIdStats(wordId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(wordId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWordsWordIdStats>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetWordsWordIdStatsQueryResult = NonNullable<Awaited<ReturnType<typeof getWordsWordIdStats>>>
export type GetWordsWordIdStatsQueryError = void


export function useGetWordsWordIdStats<TData = Awaited<ReturnType<typeof getWordsWordIdStats>>, TError = void>(
 wordId: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsWordIdStats>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWordsWordIdStats>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetWordsWordIdStats<TData = Awaited<ReturnType<typeof getWordsWordIdStats>>, TError = void>(
 wordId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsWordIdStats>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWordsWordIdStats>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetWordsWordIdStats<TData = Awaited<ReturnType<typeof getWordsWordIdStats>>, TError = void>(
 wordId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsWordIdStats>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Get statistics for a word
 */

export function useGetWordsWordIdStats<TData = Awaited<ReturnType<typeof getWordsWordIdStats>>, TError = void>(
 wordId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsWordIdStats>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetWordsWordIdStatsQueryOptions(wordId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Skip a word
 */
export const postWordsWordIdSkip = (
    wordId: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<void>(
      {url: `/words/${wordId}/skip`, method: 'POST', signal
    },
      );
    }
  


export const getPostWordsWordIdSkipMutationOptions = <TError = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWordsWordIdSkip>>, TError,{wordId: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postWordsWordIdSkip>>, TError,{wordId: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postWordsWordIdSkip>>, {wordId: number}> = (props) => {
          const {wordId} = props ?? {};

          return  postWordsWordIdSkip(wordId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostWordsWordIdSkipMutationResult = NonNullable<Awaited<ReturnType<typeof postWordsWordIdSkip>>>
    
    export type PostWordsWordIdSkipMutationError = void

    /**
 * @summary Skip a word
 */
export const usePostWordsWordIdSkip = <TError = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWordsWordIdSkip>>, TError,{wordId: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postWordsWordIdSkip>>,
        TError,
        {wordId: number},
        TContext
      > => {

      const mutationOptions = getPostWordsWordIdSkipMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get learning statistics
 */
export const getWordsLearningStats = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<GetWordsLearningStats200>(
      {url: `/words/learning-stats`, method: 'GET', signal
    },
      );
    }
  

export const getGetWordsLearningStatsQueryKey = () => {
    return [`/words/learning-stats`] as const;
    }

    
export const getGetWordsLearningStatsQueryOptions = <TData = Awaited<ReturnType<typeof getWordsLearningStats>>, TError = void>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsLearningStats>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWordsLearningStatsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWordsLearningStats>>> = ({ signal }) => getWordsLearningStats(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWordsLearningStats>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetWordsLearningStatsQueryResult = NonNullable<Awaited<ReturnType<typeof getWordsLearningStats>>>
export type GetWordsLearningStatsQueryError = void


export function useGetWordsLearningStats<TData = Awaited<ReturnType<typeof getWordsLearningStats>>, TError = void>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsLearningStats>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWordsLearningStats>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetWordsLearningStats<TData = Awaited<ReturnType<typeof getWordsLearningStats>>, TError = void>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsLearningStats>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWordsLearningStats>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetWordsLearningStats<TData = Awaited<ReturnType<typeof getWordsLearningStats>>, TError = void>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsLearningStats>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Get learning statistics
 */

export function useGetWordsLearningStats<TData = Awaited<ReturnType<typeof getWordsLearningStats>>, TError = void>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsLearningStats>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetWordsLearningStatsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get random not learned words with example sentences
 */
export const getWordsRandomNotLearnedWithExamples = (
    params?: GetWordsRandomNotLearnedWithExamplesParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<GetWordsRandomNotLearnedWithExamples200Item[]>(
      {url: `/words/random-not-learned-with-examples`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetWordsRandomNotLearnedWithExamplesQueryKey = (params?: GetWordsRandomNotLearnedWithExamplesParams,) => {
    return [`/words/random-not-learned-with-examples`, ...(params ? [params]: [])] as const;
    }

    
export const getGetWordsRandomNotLearnedWithExamplesQueryOptions = <TData = Awaited<ReturnType<typeof getWordsRandomNotLearnedWithExamples>>, TError = unknown>(params?: GetWordsRandomNotLearnedWithExamplesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsRandomNotLearnedWithExamples>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWordsRandomNotLearnedWithExamplesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWordsRandomNotLearnedWithExamples>>> = ({ signal }) => getWordsRandomNotLearnedWithExamples(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWordsRandomNotLearnedWithExamples>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetWordsRandomNotLearnedWithExamplesQueryResult = NonNullable<Awaited<ReturnType<typeof getWordsRandomNotLearnedWithExamples>>>
export type GetWordsRandomNotLearnedWithExamplesQueryError = unknown


export function useGetWordsRandomNotLearnedWithExamples<TData = Awaited<ReturnType<typeof getWordsRandomNotLearnedWithExamples>>, TError = unknown>(
 params: undefined |  GetWordsRandomNotLearnedWithExamplesParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsRandomNotLearnedWithExamples>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWordsRandomNotLearnedWithExamples>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetWordsRandomNotLearnedWithExamples<TData = Awaited<ReturnType<typeof getWordsRandomNotLearnedWithExamples>>, TError = unknown>(
 params?: GetWordsRandomNotLearnedWithExamplesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsRandomNotLearnedWithExamples>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWordsRandomNotLearnedWithExamples>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetWordsRandomNotLearnedWithExamples<TData = Awaited<ReturnType<typeof getWordsRandomNotLearnedWithExamples>>, TError = unknown>(
 params?: GetWordsRandomNotLearnedWithExamplesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsRandomNotLearnedWithExamples>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Get random not learned words with example sentences
 */

export function useGetWordsRandomNotLearnedWithExamples<TData = Awaited<ReturnType<typeof getWordsRandomNotLearnedWithExamples>>, TError = unknown>(
 params?: GetWordsRandomNotLearnedWithExamplesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWordsRandomNotLearnedWithExamples>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetWordsRandomNotLearnedWithExamplesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




